<template>
  <OkModal v-if="$store.state.openOk == true"/>
  <AddPersonVue v-if="$store.state.openAdd == true"/>
  <NewPerson v-if="$store.state.openNew == true" :departmentID="$route.params.slug" @employeeAdded="newMethod()"/>
  <div class="home">
      <Block />
      <div class="home__right">
        <div class="home__right__title">
          <p>Табель</p>
          <div class="home__right__title__buttons">
            <button @click="openTable = 'norm'" :class="{activeButton: openTable == 'norm'}">Табель Нормы</button>
            <button @click="openTable = 'premia'" :class="{activeButton: openTable == 'premia'}" v-if="this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr' ||  this.$store.state.prophile.role[0] == 'treasurer'">Табель Премии</button>
            <button @click="openTable = 'default'" :class="{activeButton: openTable == 'default'}">Табель работников</button>
          </div>
        </div>
        <div class="home__right__selects">
          <div class="home__right__selects__me">
            <select type="select" class="add__inputs__select" v-model="selectedPosition" @change="filterEmployees">
                    <option value="" disabled selected hidden>Выберите позицию</option>
                    <option value="">Все отделы</option>
                    <option v-for="item in positionsInGroups" :key="item.id" :value="item.title">{{ item.title }}</option>
            </select>
          </div>
          <div class="home__right__selects__choosedate">
            <select type="select" class="add__inputs__select" v-model="$store.state.selectedYear" @change="newYear()">
                    <option value="" disabled selected hidden>{{$store.state.selectedYear}}</option>
                    <option v-for="item in years" :key="item.id">{{ item }}</option>
            </select>
            <select type="select" class="add__inputs__select" v-model="$store.state.selectedMonth" @change="newMonth()">
                    <option value="" disabled selected hidden>{{$store.state.selectedMonth}}</option>
                    <option v-for="item in months" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
          </div>
          <div class="home__right__selects__tables">
            <div class="home__right__selects__tables__in" v-for="(item, index) in rightTable" :key="index" v-if="(this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr' || this.$store.state.prophile.role[0] == 'manager') || this.$store.state.prophile.role[0] == 'treasurer'">
              <div class="home__right__selects__tables__in__name" @click="toggleSelect(index, item)">
                <img :src="require('@/assets/icons/' + (item && item.img ? item.img : '') + '.svg')" v-if="item && item.img"/>
                <p>{{ item && item.name ? item.name : '' }}</p>
                <img :src="require('@/assets/icons/' + (this.selectOpenIndex === index && item && item.select1 ? item.select1 : item && item.select ? item.select : '') + '.svg')" v-if="item && (item.select || item.select1)"/>
              </div>
            </div>
          </div>
        </div>
        <div class="line">
        </div>
        <div class="table" v-if="openTable == 'premia'">
          <div class="table__titles">
            <div class="table__titles__in" v-for="item in titles" :key="item">
              <div class="table__titles__in__name">{{ item.name }}</div>
              <div class="table__titles__in__position"> {{ item.position }}</div>
              <div class="table__titles__in__shift"> {{ item.shift }}</div>
              <div class="table__titles__in__salary"> {{ item.salary }}</div>
              <div class="table__titles__in__hours"> {{ item.hours }}</div>
              <div class="table__titles__in__norma"> {{ item.norma }}</div>
              <div class="table__titles__in__hoursforpay">Ставка тенге/час</div>
              <div class="table__titles__in__permiahours">ПРЕМИЯ часы</div>
              <div class="table__titles__in__premiapay">Премия к рассчету</div>
              <div class="table__titles__in__company">Компания</div>
            </div>
          </div>
          <div class="table__stuff">
            <div class="table__stuff__in" v-for="group in filteredNormGroups" :key="group.title">
              <div class="table__stuff__in__title">
                <p>{{ group.name }}</p>
              </div>
              <div class="table__stuff__in__employees">
                <div class="table__stuff__in__employees__in" v-for="person in group.employees" :key="person.name">
                  <div class="person">
                    <div class="person__name">
                      <input v-model="person.name" disabled/>
                    </div>
                    <div class="person__position">
                      <input v-model="person.position" disabled/>
                    </div>
                    <div class="person__shift">
                      <input v-model="person.shift" disabled/>
                    </div>
                    <div class="person__salary">
                      <input v-model="person.salary_gross" disabled/>
                    </div>
                    <div class="person__hours">
                      <input v-model="person.workhours_count" disabled/>
                    </div>
                    <div class="person__norma">
                      <input :placeholder="person.norm" :disabled="$store.state.confirm"/>
                    </div>
                    <div class="person__hoursforpay">
                      <input :placeholder="person.hourly_rate" :disabled="$store.state.confirm"/>
                    </div>
                    <div class="person__permiahours">
                      <input :placeholder="person.overtime" :disabled="$store.state.confirm"/>
                    </div>
                    <div class="person__premiapay">
                      <input :placeholder="person.overtime_salary" :disabled="$store.state.confirm"/>
                    </div>
                    <div class="person__company">
                      <input v-model="person.company" disabled/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table" v-if="openTable == 'default' || openTable == 'norm'">
          <div class="table__titles">
            <div class="table__titles__in" v-for="item in titles" :key="item">
              <div class="table__titles__in__name">{{ item.name }}</div>
              <div class="table__titles__in__position"> {{ item.position }}</div>
              <div class="table__titles__in__number">{{ item.number }}</div>
              <div class="table__titles__in__shift"> {{ item.shift }}</div>
              <div class="table__titles__in__numbers" v-if="item.numbers"> 
                <div class="table__titles__in__numbers__in" v-for="(number, idx) in item.numbers" :key="number"> 
                  {{idx + 1}}
                </div>
              </div>
              <div class="table__titles__in__days"> {{ item.days }}</div>
              <div class="table__titles__in__hours"> {{ item.hours }}</div>
              <div class="table__titles__in__norma"> {{ item.norma }}</div>
              <div class="table__titles__in__plus" v-if="openTable == 'default'"> {{ item.plus }}</div>
              <div class="table__titles__in__bin">{{item.bin}}</div>
              <div class="table__titles__in__company"> {{ item.company }}</div>
            </div>
          </div>
          <div class="table__stuff">
            <div class="table__stuff__in" v-for="group in filteredGroups" :key="group.title">
              <div class="table__stuff__in__title">
                <p>{{ group.name }}</p>
              </div>
              <div class="table__stuff__in__employees">
                <div class="table__stuff__in__employees__in" v-for="person in group.employees" :key="person.name">
                  <div class="person">
                    <div class="person__name">
                      <input v-model="person.name" disabled/>
                    </div>
                    <div class="person__position">
                      <input v-model="person.position" disabled/>
                    </div>
                    <div class="person__number">
                      <input v-model="person.number" disabled/>
                    </div>
                    <div class="person__shift">
                      <input v-model="person.shift" disabled/>
                    </div>
                    <div class="person__workdays" v-if="openTable == 'default'">
                      <div class="person__workdays__in" v-for="(item) in person.graphics" :key="item" :style="{'background-color': getAbsenceColor(item) + '!important'}" :class="{ personEdit: !$store.state.confirm, isWorkday: (item.isWorkday == 1 || item.workhours != '0') }" @click="editedItem(item, person.id)">  
                        <input v-if="item.absence && item.workhours == '0'" 
                          type="text" 
                          :readonly="!$store.state.confirm" 
                          @blur="saveCellDate($event.target.value, item.absence.type, item, person.id)" 
                          @input="validateInput($event)"
                          style="background: transparent; outline: none; border: none; width: 100%; " 
                          :value="item.absence.type"/>
                        <div v-else-if="item.holiday && item.workhours == 0">П</div>
                        <input v-else-if="item.workhours != '0' || item.isWorkday == '1'" type="text" @input="validateInput($event)" :readonly="!$store.state.confirm" @blur="saveCellDate($event.target.value, item.workhours, item, person.id)" style="background: transparent; outline: none; border: none;" :value="item.workhours" />
                      </div>
                    </div>
                    <div class="person__workdays" v-if="openTable == 'norm'">
                      <div class="person__workdays__in" v-for="(item) in person.graphics" :key="item" :class="{ personEdit: !$store.state.confirm, isWorkday: item.isWorkday == 1}" @click="editedItem(item, person.id)">
                        <input v-if="item.isWorkday == '1'" type="text" readonly @blur="saveCellDate($event.target.value, item.workhours, item, person.id)" style="background: transparent; outline: none; border: none;" :value="person.shift_hours" />
                        <p v-else>В</p> 
                      </div>
                    </div>
                    <div class="person__days" v-if="openTable == 'default'">
                      <input :placeholder="person.workdays_count" disabled/>
                    </div>  
                    <div class="person__hours" v-if="openTable == 'default'">
                      <input :placeholder="person.workhours_count" disabled/>
                    </div>
                    <div class="person__days" v-if="openTable == 'norm'">
                      <input :placeholder="person.norm_days" disabled/>
                    </div>
                    <div class="person__hours" v-if="openTable == 'norm'">
                      <input :placeholder="person.norm" disabled/>
                    </div>
                    <div class="person__norma">
                      <input :placeholder="person.norm" :disabled="$store.state.confirm"/>
                    </div>
                    <div class="person__plus" v-if="openTable == 'default'">
                      <input :placeholder="getPlusMinus(person.norm, person.workhours_count)" class="inputPlus" :class="{isRed : getPlusMinus(person.norm, person.workhours_count)<0}" disabled/>
                    </div>
                    <div class="person__bin">
                      <input :placeholder="person.bin" disabled/>
                    </div>
                    <div class="person__company">
                      <input v-model="person.company" disabled/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button v-if="!$store.state.confirm" class="buttonNew" @click="$store.state.confirm = true">
          Выйти из режима редактировние
        </button>
      </div>
      <div class="editorBackground" v-if="!$store.state.confirm && $store.state.openEdit == true">
        <div class="editor">
          <div class="editor__top">
            <input placeholder="Укажите цифру" v-model="editItem.workhours"/>
          </div>
          <div class="editor__bot">
            <select v-model="editItem.absence">
              <option value="null" disabled selected hidden>Выберите букву</option>
              <option v-for="(item, idx) in newAbsences" :key="idx" :value="item">
                  {{ item.type }}
              </option>
            </select>
            <p v-if="workday1 == 1" @click="workday1 = 0, sendData()" style="cursor: pointer; color: orange; font-size: 12px;">Сделать не рабочим днем</p>
            <p v-else @click="workday1 = 1, sendData()" style="cursor: pointer; color: orange; font-size: 12px;">Сделать рабочим днем</p>
          </div>
          <p @click="editItem.absence.id = null, sendData()" style="cursor: pointer; color: red;" class="okdelete">Удалить букву</p>
          <img class="closeeditor" src="@/assets/icons/close.svg" @click="$store.state.openEdit = false" style="cursor: pointer;"/>
          <p class="okeditor" @click="$store.state.openEdit = false, sendData()" style="cursor: pointer;">Готово</p>
        </div>
      </div>
  </div>
</template>

<script>
import AddPersonVue from '@/components/AddPerson.vue';
import NewPerson from '@/components/NewPerson.vue';
import { mapState } from 'vuex';
import axios from "axios";
import OkModal from '@/components/OkModal.vue';
import Block from '@/components/Block.vue';
import { toast } from 'vue3-toastify';
// @ is an alias to /src
export default {
  components: {
    AddPersonVue, NewPerson, OkModal, Block
  },

  data(){
        return{
            activeIndex: false,
            openTable : 'default',
            selectOpen: false,
            selectOpenIndex: null,
            selectedTag: null,
            selectImageIndex: 0,
            newPosistions: false,
            selectedPosition: '',
            color:'',
            editItem: {
              workhours: 0,
              absence: null,
              date: '',
              holiday: 0,
              sickleave: 0,
              vacation: 0,
              overtime: 0,
              is_null: ''
            },
            months: [
              { id: 0, name: 'Январь' },
              { id: 1, name: 'Февраль' },
              { id: 2, name: 'Март' },
              { id: 3, name: 'Апрель' },
              { id: 4, name: 'Май' },
              { id: 5, name: 'Июнь' },
              { id: 6, name: 'Июль' },
              { id: 7, name: 'Август' },
              { id: 8, name: 'Сентябрь' },
              { id: 9, name: 'Октябрь' },
              { id: 10, name: 'Ноябрь' },
              { id: 11, name: 'Декабрь' }
            ],
            rightTable: [
              {
                img: 'righttable1',
                name: 'Пользователь',
              },
              {
                img: 'righttable2',
                name: 'Экспорт',
              },
              {
                img: 'righttable3',
                name: 'Изменить',
              },
              {
                img: 'righttable4',
                name: 'Обновить',
              },
            ],
            titles:[
              {
                name: 'ФИО',
                salary: 'Оклад',
                position: 'Должность',
                number: 'Номер',
                shift: 'Смена',
                numbers: [],
                days: 'Дни',
                hours: 'Часы',
                norma: 'Норма',
                bin: 'Бин',
                plus: '+/-',
                company: 'Компания',
              },
            ],
            newGroups: false,
            years: [],
            personId:  0,
            newAbsences: false,
            newHolidays: false,
            newSelectedMonth: 1,
            norms: false,
            newSelectedYear: 2025,
            workday1: ''
        }
    },
    async created() {
        this.getArrayMonth()
        this.getPage()  
    },
    methods: {
      newMethod(){
        this.getNewTable()
      },
      newYear(){
        this.newSelectedYear = this.$store.state.selectedYear
        this.getNewTable()
        this.getArrayMonth()
        this.getTable()
      },
      newMonth(){
        this.newSelectedMonth = this.$store.state.selectedMonth + 1
        this.getNewTable()
        this.getArrayMonth()
        this.getTable()
      },
      generateYearList() {
        const currentYear = new Date().getFullYear();
        const startYear = 2020;
        for (let year = startYear; year <= currentYear; year++) {
          this.years.push(year);
        }
      },
      validateInput(event) {
        const inputValue = event.target.value;
        const onlyDigits = inputValue.replace(/\D/g, ''); // Оставляем только цифры

        if (inputValue !== onlyDigits) {
          // Устанавливаем новое значение в поле только если произошли изменения
          event.target.value = onlyDigits;
          toast.error("Можно вводить только цифры.");
        }
      },
      isHoliday(date) {
        return this.newHolidays.some(holiday => ((holiday.start_date <= date) &&(holiday.end_date >= date)) && (holiday.is_weekend === 1 || holiday.is_workday === 1));
      },
      getBackgroundColor(item) {
        if(item.holiday && item.workhours == 0){
          return 'red';
        }
        else{
          return '';
        }
      },
      getAbsenceColor(item) {
        if (item.absence && item.absence.color) {
          return item.absence.color;
        } else {
          return this.getBackgroundColor(item); // или можно вернуть другое значение по умолчанию, если пустота не подходит
        }
      },
      getTotalWorkhours(num) {
      let total = 0;
      total = num * 8;
      return total;
    },
    getPlusMinus(num1, num2){
      let total = 0;
      total = (num2) - (num1);
      return total;
    },
    saveCellDate(value, oldText, item, id) {
        if (this.$store.state.confirm && value !== oldText) {
          const obj = {
            employee_id: id,
            date: item.date,
            workhours: value !== '' ? value : 0,
          };
          if (item.absence) {
            obj.absence_id = item.absence.id;
          }
          this.sendData(obj);
        }
      },
      sendData(value){
        let back = {
          employee_id: this.personId,
          date: this.editItem.date,
          workhours: this.editItem.workhours !== '' ? this.editItem.workhours : 0,
          absence_id: this.editItem.absence?.id,
          isWorkday: this.workday1
        };
        if (value) {
          back = value
        }
        axios
                .post("https://api-ems.mydev.kz/api/workdays/create", back,
                {
                  headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                    .then((response) => {
                      if (response.status === 200) {
                        this.$store.state.openEdit = false
                      } else {
                        console.log(response);
                      }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
      },
      editedItem(value, value1){
        this.workday1 = value.isWorkday
        this.editItem = value;
        this.personId = value1;
        this.$store.state.openEdit = true;
        console.log(this.workday1);
      },
      async getTable(){
        await this.$axios.get(`norm/get?department_id=${this.$route.params.slug}&year_month=${this.newSelectedYear}-${this.newSelectedMonth}`,
             {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
             })
            .then(res => {
                this.norms = res.data.data
            })
            .catch(err => {
            })  
      },
      async getNewTable() {
        await this.$axios.get(`tabel/get?department_id=${this.$route.params.slug}&year_month=${this.newSelectedYear}-${this.newSelectedMonth}`, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                }
            })
            .then(res => {
              console.log(this.titles[0].numbers);
                this.newGroups = res.data.data
                this.newGroups.groups.forEach(group => {
                group.employees.forEach(employee => {
                employee.graphics = this.titles[0].numbers.map(number => {
                  const dat = employee.workdays.find(el => el.date === number);
                  dat ? dat.is_null = false : ''
                  return dat || {
                    date: number,
                    is_null: true,
                    workhours: 0,
                    overtime: 0,
                    sickleave: null,
                    vacation: null,
                    holiday: null
                  };
                });
              });
              });
            })
      },
      async getPage() {    
        await this.$axios.get(`absences/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newAbsences = res.data.data
            })
            .catch(err => {
            }) 
            await this.$axios.get(`holidays/get`,
             {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
             })
            .then(res => {
                this.newHolidays = res.data.data
            })
            .catch(err => {
            })  
            await this.$axios.get(`positions/get`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
            .then(res => {
                this.newPosistions = res.data.data
            })
            .catch(err => {
            }) 
          },
      changeColor(event, value) {
        console.log(event);
        this.selectedTag = event
        this.activeIndex = value
        this.$store.state.openEdit = true
      },
      clickColor(color) {
        this.selectedTag.style.background(color)
        console.log(this.selectedTag.srcElement.style.background = color);
      },
      toggleSelect(index, item) {
        if (item.name === 'Пользователь') {
            this.$store.state.openNew = !this.$store.state.openNew;
          }
          if (item.name === 'Обновить'){
            location.reload();
          }
          if(item.name === 'Экспорт' && this.openTable === 'default'){
            const url = `https://api-ems.mydev.kz/api/export-tabel?department_id=${this.$route.params.slug}&year=${this.$store.state.selectedYear}&month=${this.newSelectedMonth}`;
            window.location.href = url;
          }
          if(item.name === 'Экспорт' && this.openTable === 'premia'){
            const url = `https://api-ems.mydev.kz/api/export-prem?department_id=${this.$route.params.slug}&year=${this.$store.state.selectedYear}&month=${this.newSelectedMonth}`;
            window.location.href = url;
          }
          if(item.name === 'Экспорт' && this.openTable === 'norm'){
            const url = `https://api-ems.mydev.kz/api/export-norm?department_id=${this.$route.params.slug}&year=${this.$store.state.selectedYear}&month=${this.newSelectedMonth}`;
            window.location.href = url;
          }
        if (this.selectOpenIndex === index) {
          this.selectOpenIndex = null;
        } else {
          this.selectOpenIndex = index;
        }
        if (item.name === 'Изменить' && (this.$store.state.prophile.role[0] == 'admin' || this.$store.state.prophile.role[0] == 'hr' || this.$store.state.prophile.role[0] == 'manager' || this.$store.state.prophile.role[0] == 'treasurer'  )) {
          this.$store.state.openOk = !this.$store.state.openOk;
        }
        this.selectImageIndex = this.selectImageIndex === 0 ? 1 : 0;
      },
      getArrayMonth() {
        const today = new Date();
        const year = this.$store.state.selectedYear 
        const month = this.$store.state.selectedMonth 
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        this.titles[0].numbers = [];
        for (let day = 2; day <= 32; day++) {
          const formattedDay = new Date(year, month, day).toISOString().slice(0, 10); // Форматируем день в строку "YYYY-MM-DD"
          this.titles[0].numbers.push(formattedDay);
        }
      }
    },
    computed: {
      positionsInGroups() {
        const groupPositions = [];
        if (this.newGroups && this.newGroups.groups && this.newPosistions) {
          this.newGroups.groups.forEach(group => {
            if (group && group.employees) {
              group.employees.forEach(employee => {
                const position = this.newPosistions.find(pos => pos.title === employee.position);
                if (position && !groupPositions.some(pos => pos.id === position.id)) {
                  groupPositions.push(position);
                }
              });
            }
          });
        }
        return groupPositions;
      },
      ...mapState(['openAdd']),
      filteredGroups() {
        if (!this.selectedPosition || this.selectedPosition === '') {
          // Если позиция не выбрана, вернуть все группы со всеми сотрудниками
          return this.newGroups.groups;
        } else {
          // Если выбрана позиция, вернуть группы с отфильтрованными сотрудниками
          return this.newGroups.groups.map(group => ({
            ...group,
            employees: group.employees.filter(person => person.position === this.selectedPosition)
          })).filter(group => group.employees.length > 0);
        }
      },
      filteredNormGroups() {
        if (!this.selectedPosition || this.selectedPosition === '') {
          // Если позиция не выбрана, вернуть все группы со всеми сотрудниками
          return this.norms.groups;
        } else {
          // Если выбрана позиция, вернуть группы с отфильтрованными сотрудниками
          return this.norms.groups.map(group => ({
            ...group,
            employees: group.employees.filter(person => person.position === this.selectedPosition)
          })).filter(group => group.employees.length > 0);
        }
      }
    },
    mounted() {
        this.newSelectedMonth = this.$store.state.selectedMonth + 1
        this.getNewTable()
        this.getArrayMonth()
        this.getTable()
        let tableD = localStorage.getItem('tableData')
        if (tableD) {
          this.groups = JSON.parse(tableD) 
        }
        this.generateYearList();
      // Добавляем созданный массив к объектам внутри titlesin, которые имеют свойство numbers
    }
}
</script>
<style lang="scss" scoped> 
.isWorkday{
  background: #00ff62 !important;
}
.isRed{
  &::placeholder{
    color: rgb(255, 0, 0) !important;
  }
}
.inputPlus{
  &::placeholder{
    color: rgb(78, 211, 73);
  }
}

.buttonNew{
  width: fit-content;
  color: red;
  background: inherit;
  padding: 6px 12px;
  border: 1px solid rgba(196, 181, 181, 0.993);
  border-radius: 10px;
  cursor: pointer;
}
.blueBackground{
  background: #1965EA !important;
  color: white !important;
  border: 1px solid red !important;
}
.greenBackground{
  background: #00AC4F !important;
  color: white !important;
  border: 1px solid red !important;
}
.purpleBackground{
  background: #8A00AC !important;
  color: white !important;
  border: 1px solid red !important;
}
.pinkBackground{
  background: #FF575733 !important;
  color: white !important;
  border: 1px solid red !important;
}

.personEdit{
        border: 1px solid rgb(2, 126, 2) !important;
        cursor: pointer;
      &::placeholder{
        color: grey !important;
      }
  }
.person{
  display: flex;
  gap: 5px;
  input{
    border: 1px solid #0000000D;
        padding: 5px 8px;
        height: fit-content;
        border-radius: 5px;
        font-size: 11px;
        font-weight: 300;
        text-align: center;
        width: 100%;
  }
  &__workdays{
    display: flex;
    gap: 3px;
        &__in{
          background: rgba(0, 0, 0, 0.0509);
          width: 30px;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          font-size: 11px;
          font-weight: 300;
        }
  }
  &__name{
    min-width: 400px;
      }
      &__position{
        min-width: 200px;
      }
      &__bin{
        min-width: 150px;
      }
      &__shift{
        min-width: 100px;
      }
      &__number{
        min-width: 100px;
      }
      &__days{
        min-width: 50px;
      }
      &__hours{
        min-width:50px;
      }
      &__norma{
        min-width: 50px;
      }
      &__plus{
        min-width:50px;
      }
      &__company{
        min-width: 150px;
      }
      &__salary{
        min-width: 200px;
      }
      &__permiahours{
        min-width: 150px;
      }
      &__premiapay{
        min-width: 150px;
      }
      &__hoursforpay{
        min-width: 150px;
      }
}
.closeeditor{
  width: 20px;
  height: 20px;
  position: absolute;
  right: 5%;
}
.okeditor{
  position: absolute;
  bottom: 10%;
  right: 5%;
  color: #1965EA;
}
.okdelete{
  position: absolute;
  bottom: 10%;
}
.editorBackground{
  position: absolute;
        z-index: 999;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
}
.editor{
        width: 20%;
        display: flex;
        flex-direction: column;
        position: relative;
        gap: 31px;
        padding: 20px;
        background: white;
        color: rgba(145, 151, 179, 0.50);
        border: 1px solid #0000001A;
        border-radius: 10px;
        font-size: 18px;
        font-weight: 300;
        height: 220px;
        &__top{
          display: flex;
          flex-direction: column;
          gap: 18px;
          width: 100%;
          input{
                outline: none;
                background: inherit !important;
                width: 60% !important;
                border-bottom: 2px solid rgba(25, 101, 234, 0.19) !important;
                padding-bottom: 10px;
                border: none;
                color: black;
                &::placeholder{
                color: #90909080;
                }
          }
        }
        &__bot{
          display: flex;
          flex-direction: column;
          gap: 20px;
          select{
            outline: none;
                background: inherit !important;
                width: 60% !important;
                border-bottom: 2px solid rgba(25, 101, 234, 0.19) !important;
                padding-bottom: 10px;
                border: none;
                color: black;
                &::placeholder{
                color: #90909080;
                }
          }
        }
}
.line{
  border: 1px solid #EEEEEE
}
.line3{
  border: 1px solid #EEEEEE;
}
.stuff{
  display: flex;
  flex-direction: column;
  gap: 5px;
  &__in{
    &__titles{
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__in{
      display: flex;
      &:first-child{
          width: 9%;
      }
      &:nth-child(2){
          width: 10%;
      }
      &:nth-child(3){
        width: 58%;
      }
      &:nth-child(4){
        width: 3%;
      }
      &:nth-child(5){
        width: 3%;
      }
      &:nth-child(6){
        width: 3%;
      }
      &:nth-child(7){
        width: 3%;
      }
      &:nth-child(8){
        width: 10%;
      }
      gap: 5px;
      &__title{
        display: flex;
        align-items: center;
        input{
        border: 1px solid #0000000D;
        padding: 5px 8px;
        height: fit-content;
        border-radius: 5px;
        font-size: 11px;
        font-weight: 300;
        width: 100%;
        text-align: center;
        }
        .activeNumber{
            color: #C7C7C7;
          }
      }
      &__numbers{
        display: flex;
        gap: 2px;
        align-items: center;
        &__in{
          display: flex;
          align-items: center;
          input{
          color: black;
          width: 30px;
          height: 25px;
          text-align: center;
          border: none;
          outline: none;
          border-radius: 5px;
          font-size: 11px;
          font-weight: 300;
          }
          .activeNumber{
            color: #C7C7C7;
            cursor: pointer;
          }
        }
      }
    }
  }
  }
}
.table{
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  width: 100%;
  gap: 20px;
  &__stuff{
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 90vh;
    width: fit-content;
    overflow-y: auto;
    scrollbar-width: 0px;
    &__in{
      display: flex;
      flex-direction: column;
      gap: 20px;
      &__title{
        padding: 10px 0px;
        background: #909090;
        text-align: center;
        width: 400px;
        color: white;
      }
      &__employees{
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

    }
  }
  &__titles{
    &__in{
      display: flex;
      gap: 5px;
      &__name, &__position, &__days, &__hours, &__norma, &__plus, &__company, &__shift, &__number, &__bin, &__salary, &__hoursforpay, &__premiapay, &__permiahours{
        border: 1px solid #0000000D;
        padding: 5px 8px;
        height: fit-content;
        border-radius: 5px;
        font-size: 11px;
        font-weight: 300;
        text-align: center;
      }
      &__salary{
        min-width: 200px;
      }
      &__permiahours{
        min-width: 150px;
      }
      &__premiapay{
        min-width: 150px;
      }
      &__hoursforpay{
        min-width: 150px;
      }
      &__name{
        min-width: 400px;
      }
      &__position{
        min-width: 200px;
      }
      &__bin{
        min-width: 150px;
      }
      &__shift{
        min-width: 100px;
      }
      &__number{
        min-width: 100px;
      }
      &__days{
        min-width: 50px;
      }
      &__hours{
        min-width:50px;
      }
      &__norma{
        min-width: 50px;
      }
      &__plus{
        min-width:50px;
      }
      &__company{
        min-width: 150px;
      }
      &__numbers{
        display: flex;
        gap: 3px;
        &__in{
          background: #EBEBEB;
          width: 30px;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          font-size: 11px;
          font-weight: 300;
        }
      }
    }
  }
}
.activeButton{
  background: #1965EA30 !important;
  border: 3px solid #1965EA30 !important;
}
  .home{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: end;
    &__right1{
      padding: 50px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 87%;
      &__title{
        font-size: 22px;
        font-weight: 600;
      }
      &__buttons{
        display: flex;
        gap: 25px;
        button{
          padding: 8px 30px;
          font-size: 14px;
          color: #1965EA;
          border: 1px solid #1965EA30;
          border-radius: 10px;
          background: none;
        }
      }
      &__tableTop{
        display: flex;
        justify-content: space-between;
        border: 1px solid #0000001A;
        padding: 11px 86px;
        div{
          width: 13%;
          text-align: center;
          font-size: 14px;
        }
      }
      &__table{
        display: flex;
        flex-direction: column;
        border: 1px solid #0000001A;
        &__in{
          display: flex;
          justify-content: space-between;
          padding: 11px 86px;
          div{
          width: 13%;
          text-align: center;
          font-size: 14px;
        }
        }
      }
    }
    &__right{
      padding: 50px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 87%;
      &__title{
        font-size: 22px;
        font-weight: 600;
        gap: 20px;
        display: flex;
        flex-direction: column;
        &__buttons{
          display: flex;
          gap: 20px;
          button{
            width: 200px;
            border: 3px solid #1965EA30;
            padding: 7px 0px;
            border-radius: 10px;
            background: white;
            cursor: pointer;
            transition: all 0.5s ease;
            &:hover{
              background: #1965EA30;
              border: 3px solid #1965EA30;
            }
          }
        }
      }
      &__selects{
        display: flex;
        gap: 25px;
        &__me{
          select{
            border: 1px solid #1965EA30;
            padding: 9px 16px;
            border-radius: 10px 10px 0px 0px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            height: auto;
            font-size: 14px;
            font-weight: 500;
            color: #909090;
            z-index: 9;
            background: white;
            outline: none;
          }
        }
        &__choosedate{
          display: flex;
          gap: 20px;
          select{
            border: 1px solid #1965EA30;
            padding: 9px 16px;
            border-radius: 10px 10px 0px 0px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            height: auto;
            font-size: 14px;
            font-weight: 500;
            color: #909090;
            z-index: 9;
            background: white;
            outline: none;
          }
        }
        &__tables{
          display: flex;
          gap: 25px;
          align-items: start;
          &__in{
            position: relative;
            cursor: pointer;
            &__name{
            padding: 8px 16px;
            &:first-child{
                border-radius: 10px 10px 0px 0px;
            }
            display: flex;
            justify-content: space-between;
            border: 1px solid #1965EA30;
            gap: 15px;
            border-radius: 10px;
            align-items: center;
            color: #909090;
            position: relative;
          }
          &__select{
            border: 1px solid #1965EA30;
            padding: 8px 16px;
            border-radius: 0px 0px 10px 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            font-size: 14px;
            font-weight: 500;
            position: absolute;
            width: 100%;
            z-index: 9;
            background: white;
          }
          }
        }
      }
    }
  }
</style>
