import { createStore } from 'vuex'
import axios from "axios";

export default createStore({
  state: {
    openAdd: false,
    openNew: false,
    openOk: false,
    confirm: true,
    openEdit: false,
    selectedDepartment: '',
    selectedYear: 2025,
    selectedMonth: new Date().getMonth(), // Установите текущий месяц
    select: 0,
    openSelect: false,
    auth: localStorage.getItem("access_token") ? true : false,
    user: {},
    openOwner: false,
    openZam: false,
    currentTime: '',
    currentDate: '',
    prophile: JSON.parse(localStorage.getItem('user'))
  },
  getters: {
    getAuth(state) {
      return state.auth;
    },
    getUser: (state) => state.user,
  },
  mutations: {
    SET_AUTH(state, auth) {
      state.auth = auth;
    },
    SET_USER(state, user) {
      state.user = user;
    }
  },
  actions: {
    logoutUser({ commit }) {
      localStorage.removeItem("access_token");
      commit("SET_USER", {});
      commit("SET_AUTH", false);
    },
    checkAuth({ commit }) {
      if (localStorage.getItem("access_token")) commit("SET_AUTH", true);
      else commit("SET_AUTH", false);
    },
    async requestUser({commit}) {
      await axios.post(`https://api-ems.mydev.kz/api/me`, [] , {
          headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
      })
      .then(response => {
        commit("SET_USER", response.data);
        commit("SET_AUTH", true);
      })

      .catch(error => {
          commit("SET_USER", {});
          commit("SET_AUTH", false);
          localStorage.removeItem("access_token");
      })
    }
  },
  modules: {
  }
})